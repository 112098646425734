const Pagination = ({ currentPage, totalPages, setCurrentPage, firstRowRef }) => {
    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        if (firstRowRef.current) {
            firstRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const generatePageNumbers = () => {
        const pageNumbers = new Set();

        pageNumbers.add(1);
        pageNumbers.add(totalPages);

        if (currentPage > 1) {
            pageNumbers.add(currentPage - 1);
        }

        if (currentPage < totalPages) {
            pageNumbers.add(currentPage + 1);
        }

        for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
            pageNumbers.add(i);
        }

        return Array.from(pageNumbers).sort((a, b) => a - b);
    };

    return (
        <nav>
            <ul className="pagination justify-content-center mt-3">
                {currentPage > 10 && (
                    <li className="page-item">
                        <button className="page-link" onClick={() => handleClick(Math.max(currentPage - 10, 1))}>-10</button>
                    </li>
                )}
                {currentPage > 5 && (
                    <li className="page-item">
                        <button className="page-link" onClick={() => handleClick(Math.max(currentPage - 5, 1))}>-5</button>
                    </li>
                )}

                {generatePageNumbers().map(number => (
                    <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => handleClick(number)}>
                            {number}
                        </button>
                    </li>
                ))}

                {currentPage + 5 <= totalPages && (
                    <li className="page-item">
                        <button className="page-link" onClick={() => handleClick(Math.min(currentPage + 5, totalPages))}>+5</button>
                    </li>
                )}
                {currentPage + 10 <= totalPages && (
                    <li className="page-item">
                        <button className="page-link" onClick={() => handleClick(Math.min(currentPage + 10, totalPages))}>+10</button>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Pagination