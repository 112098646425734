import { RaceBy } from "@uiball/loaders";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import Title from "../../components/Title";
import PERSON_TYPE from "../../config/person_type";
import getPerson from "../../services/adp/person/get";
import obtenerListadoPersonasInterno from "../../services/adp/person/list-internal";
import { isEmpty } from "../../utils/functions";
import profileImage from '../../images/imagen-card1.png';
import Message from "../../utils/message";
import obtenerTurnosPorPersona from "../../services/adp/person/lista_turnos";
import TURN_STATE_OBJECT from "../../utils/turn_state";
import './historial.scss';
import getTurn from "../../services/adp/turn/get";
import { ModalVerDatosTurno } from "../../components/Modals/VerTurno";
import { useAuth } from "../../hooks/auth";
import Pagination from "../../components/Paginacion/Paginacion";


export default function HistorialPersona(props) {
    const { token: TOKEN } = useAuth();
    const [persons, setPersons] = useState([]);
    const [persons_original, setPersonsOriginal] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(true);

    const [open, setOpen] = useState('off');
    const [swap, setSwap] = useState('off');


    const [colaborator_id, setColaboratorId] = useState(0);

    const [colaborator, setColaborator] = useState(null);
    const [turnos, setTurnos] = useState([]);

    const [turno_id, setTurnoID] = useState(0);
    const [datosTurno, setDatosTurno] = useState([]);
    const [open_modal, setOpenModal] = useState(false);




    const firstRowRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(500); // Elementos por página
    const [currentPersons, setCurrentPersons] = useState([]);

    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentPersons(persons.slice(indexOfFirstItem, indexOfLastItem));
    }, [currentPage, persons, itemsPerPage]);

    const totalPages = Math.ceil(persons.length / itemsPerPage);


    useEffect(() => {
        obtenerListadoPersonasInterno({
            token: TOKEN, callbacks: response => {
                if (response.estado > 0 && response.resultados.length > 0) {
                    let list = response.resultados.filter(({ name, document_number }) => !isEmpty(document_number) && !isEmpty(name)).map(item => {
                        let { id, name, surname, email, document_number, document_type, person_type } = item;
                        name = name
                            .normalize('NFD')
                            // .replace(/ /g, "")
                            .toUpperCase()
                            .trim();
                        surname = surname
                            .normalize('NFD')
                            // .replace(/ /g, "")
                            .toUpperCase()
                            .trim();
                        email = email ? email
                            .normalize('NFD')
                            // .replace(/ /g, "")
                            .toUpperCase()
                            .trim()
                            : '';
                        document_number = document_number ? document_number
                            .normalize('NFD')
                            // .replace(/ /g, "")
                            .toUpperCase()
                            .trim()
                            : '';

                        const person = {
                            id,
                            name,
                            surname,
                            email,
                            document_number,
                            document_type,
                            person_type
                        }
                        return person;
                    }).sort((a, b) => a.surname.localeCompare(b.surname));

                    setPersons(list.filter(person => person.person_type === PERSON_TYPE.COLABORADOR.name)
                    );
                    setPersonsOriginal(list.filter(person => person.person_type === PERSON_TYPE.COLABORADOR.name)
                    );
                }
                setLoading(false);
            }
        });
    }, [TOKEN]);

    const searchColaborador = ({ target }) => {
        const { value } = target;
        if (!isEmpty(value)) {
            let resultTemp = persons.filter(item => {
                const nombreYApelldo = `${item.name} ${item.surname}`;
                return nombreYApelldo.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
                    item.email?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
                    item.document_number?.toLocaleLowerCase().includes(value.toLocaleLowerCase())
            });
            setPersons(resultTemp.filter(person => person.person_type === PERSON_TYPE.COLABORADOR.name)
            );
        } else {
            setPersons(persons_original.filter(person => person.person_type === PERSON_TYPE.COLABORADOR.name)
                .sort((a, b) => b.surname - a.surname));
        }
    }

    const openModal = ({ id }) => {
        setOpen('on');
        setSwap('on');
        setLoader(true);
        getPerson({
            token: TOKEN, id: id, callbacks: response => {
                if (response.estado > 0) {
                    setColaborator(response.resultados[0]);
                    setColaboratorId(id);
                    obtenerTurnosPorPersona({
                        token: TOKEN, id: id, callbacks: result => {
                            if (result.estado > 0) {
                                if (result.resultados !== null) {
                                    if (result.resultados.length > 0) {
                                        setTurnos(result.resultados);
                                        setLoader(false);
                                    } else {
                                        setTurnos([]);
                                        setLoader(false);
                                    }
                                } else {
                                    setTurnos([]);
                                    setLoader(false);
                                }
                            } else {
                                setTurnos([]);
                                setLoader(false);
                            }
                        }
                    })
                } else {
                    Message({ message: response.mensaje ?? response.mensaje, type: 'error' });
                    setLoader(false);
                }
            }
        })
    }


    const closeModal = () => {
        setColaboratorId(null);
        setOpen('off');
        setSwap('off');
    }

    const verDetallesTurno = async ({ id }) => {
        setLoading(true);
        await getTurn({
            id: id, token: TOKEN, callbacks: response => {
                if (response.estado > 0) {
                    setDatosTurno(response.resultados);
                    setTurnoID(response.resultados.turno_id);
                    setOpenModal(true);
                    setLoading(false);
                } else {
                    Message({ message: response.mensaje ?? response.mensaje, type: 'error' });
                    setLoading(false);
                }
            }
        })
    }
    return (
        <>
            <main>
                <div className="container-fluid ps-0">
                    <div className="glassDatos">
                        <div className="pb-4 d-flex align-items-center justify-content-between">
                            <Title title="Historial de turnos por persona" class="titlegray" />
                            <input className="formatInputs w-50" type="search" placeholder="Buscar colaborador..." onChange={searchColaborador} />
                        </div>
                        {
                            loading &&
                            <div className="containerLoader">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Cargando...</span>
                                </Spinner>
                            </div>
                        }
                        <div id="DatosPersonaTurno w-auto">
                            <div className="shadowClose" data-close={open} data-swap={swap} onClick={() => closeModal()}></div>
                            <div className="containerDatosPersonaCard d-block w-75 px-2" data-swap={swap}>
                                {
                                    loader &&
                                    <div className="position-absolute containerLoading">
                                        <RaceBy
                                            size={280}
                                            lineWeight={15}
                                            speed={1.4}
                                            color="black"
                                            className="d-flex"
                                        />
                                    </div>
                                }
                                {!isEmpty(colaborator_id) &&
                                    <div className="h-100 container" id="show">
                                        <button className="btnPaginationAtras cerrarModal" onClick={() => closeModal()}>
                                            <i className="fas fa-window-close"></i>
                                        </button>
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-3">
                                                <img src={profileImage} alt="imagen perfil" className="img-fluid" />
                                            </div>
                                            <div className="col-9">
                                                <h3 className="titlegray">{`${colaborator.name} ${colaborator.surname}`}</h3>
                                                <div className="mt-5 container__main__datos">
                                                    <div className="container__historial__card">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div>
                                                                    <p><b>Email:</b> <span>{colaborator.email}</span></p>
                                                                </div>
                                                                <div>
                                                                    <p><b>Legajo:</b> <span>{colaborator.legajo}</span></p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div>
                                                                    <p><b>Empresa:</b> <span>{colaborator.company}</span></p>
                                                                </div>
                                                                <div>
                                                                    <p><b>{colaborator.document_type}:</b> <span>{colaborator.document_number}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <h3 className="titlegray">Historial de turnos</h3>
                                            </div>
                                            <div className="col-12 my-5">
                                                <table className="table table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Motivo de turno</th>
                                                            <th>Sucursal</th>
                                                            <th>Empresa</th>
                                                            <th>Fecha</th>
                                                            <th>Hora</th>
                                                            <th>Estado</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    {/* {turnos !== null && turnos.length === 0 &&
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="8">
                                                                <div className="alert alert-danger">No hay turnos previos</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                } */}
                                                    {turnos.length > 0 &&
                                                        <tbody>
                                                            {
                                                                turnos.map((turno, index) => {
                                                                    const { Turno, Sucursal } = turno;
                                                                    const { id, estado, fecha, hora, empresa, motivo } = Turno;
                                                                    const { nombre, abreviacion } = motivo;
                                                                    const { nombre: sucursal_nombre } = Sucursal;
                                                                    const estadoTotal = TURN_STATE_OBJECT[estado];
                                                                    return (
                                                                        <tr key={`${index}_${abreviacion}_${id}`}>
                                                                            <td><b>#{abreviacion}-{id}</b></td>
                                                                            <td>{nombre}</td>
                                                                            <td>{sucursal_nombre}</td>
                                                                            <td>{empresa}</td>
                                                                            <td>{fecha}</td>
                                                                            <td>{hora}</td>
                                                                            <td>
                                                                                <p className="infoTurno" data-color={estadoTotal.dataColor - 1}>{estadoTotal.display}</p>
                                                                            </td>
                                                                            <td>
                                                                                <button className="btnDerivar  px-3" onClick={() => { verDetallesTurno({ id }) }}>
                                                                                    Ver
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        {
                            currentPersons.length > 0 ?
                                <div className="mt-5" ref={firstRowRef}>
                                    <table className="table table-striped table-hover mt-5">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nombre</th>
                                                <th>Apellido</th>
                                                <th>Email</th>
                                                <th>Documento</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                currentPersons.map((person, index) => {
                                                    const { id, name, surname, email, document_type, document_number } = person;
                                                    return (
                                                        <tr key={`${index}_${document_number}_${id}`}>
                                                            <td>{id}</td>
                                                            <td>{name}</td>
                                                            <td>{surname}</td>
                                                            <td>{email ?? 'S/D'}</td>
                                                            <td>{document_type}: {document_number}</td>
                                                            <td>
                                                                <button className="btnAtender  px-3" onClick={() => { openModal({ id }) }}>
                                                                    Historial
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })

                                            }
                                        </tbody>
                                    </table>

                                    {/* Paginación */}
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        setCurrentPage={setCurrentPage}
                                        firstRowRef={firstRowRef}
                                    />
                                </div>
                                :
                                <div div className="alert alert-danger my-5" role="alert">
                                    <span>No hay resultados</span>
                                </div>
                        }
                    </div>
                </div>
            </main>
            <ModalVerDatosTurno
                datosTurno={datosTurno}
                turno_id={turno_id}

                open_modal={open_modal}
                setOpenModal={setOpenModal}

            />
        </>
    );
}